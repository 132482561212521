<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('financialAid.farmer_selection') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <div class="row">
                                                 <template>
                                                    <div class="col-sm-12 col-md-12">
                                                      <div style="font-size:12px; background-color: #337982; padding:6px">
                                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                                      </div>
                                                    </div>
                                                </template>
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="fiscal_year_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('financialAid.fiscal_year')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="search.fiscal_year_id"
                                                            :options="fiscalList"
                                                            id="fiscal_year_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @change="getCircularList"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="season_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('financialAid.season')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="search.season_id"
                                                            :options="seasonSetupList"
                                                            id="season_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @change="getCircularList"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="circular_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_config.circular_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="search.circular_id"
                                                            :options="circularList"
                                                            id="circular_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="district_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                id="district_id"
                                                                plain
                                                                v-model="search.district_id"
                                                                :options="districtList"
                                                                :disabled="isReadOnly"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="upazilla_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                id="upazilla_id"
                                                                plain
                                                                v-model="search.upazilla_id"
                                                                :options="upazillaList"
                                                                :disabled="isReadOnly"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Total Farmer" vid="total_farmer">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="5"
                                                            label-for="upazilla_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('financialAid.allocatedFarmer') }}
                                                            </template>
                                                            <b-form-input
                                                            id="upazilla_id"
                                                            plain
                                                            v-model="form.total_farmer"
                                                            disabled
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <template v-if="showData">
                                                    <div class="col-sm-12 col-md-12">
                                                      <div style="font-size:12px; background-color: #337982; padding:6px">
                                                        <h5 class="text-white text-center"> {{ $t('financialAid.farmer_list') }}</h5>
                                                      </div>
                                                    </div>
                                                </template>
                                                <div class="col-sm-12 col-md-12">
                                                  <b-table-simple striped bordered small class="mt-3" v-if="showData">
                                                    <b-thead>
                                                      <b-tr>
                                                        <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                                        <b-th class="text-center align-top">{{ $t('bsri_demandDIS.farmer_name') }}</b-th>
                                                        <b-th class="text-center align-top">{{ $t('bsri_demandDIS.farmer_father_name') }}</b-th>
                                                        <b-th class="text-center align-top">{{ $t('bsri_demandDIS.nid_number') }}</b-th>
                                                        <b-th class="text-center align-top">{{ $t('common_config.far_wallet_no') }}</b-th>
                                                        <b-th class="text-center">{{ $t('globalTrans.mobile') }}</b-th>
                                                        <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                                                      </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <tr v-for="(detail, index) in form.details" :key="index">
                                                            <td class="text-center">{{ $n(index + 1) }}</td>
                                                            <td class="text-capitalize">{{ currentLocale === 'bn' ? detail.name_bn : detail.name }}</td>
                                                            <td>{{ currentLocale === 'bn' ? detail.father_name_bn : detail.father_name }}</td>
                                                            <td>{{ $n(detail.nid_no, { useGrouping: false }) }}</td>
                                                            <td>{{ $n(detail.wallet_no, { useGrouping: false }) }}</td>
                                                            <td>{{ detail.mobile_no | mobileNumber }}</td>
                                                            <td class="text-center">
                                                                <ValidationProvider name="No Of Farmer" :vid="`is_check${index}`">
                                                                    <b-form-checkbox
                                                                        type="checkbox"
                                                                        plain
                                                                        v-model="detail.is_check"
                                                                        :checked="detail.is_check"
                                                                        value="1"
                                                                        unchecked-value="0"
                                                                        @change="checkTotalFarmer(index, $event)"
                                                                    >
                                                                    </b-form-checkbox>
                                                                </ValidationProvider>
                                                            </td>
                                                        </tr>
                                                    </b-tbody>
                                                  </b-table-simple>
                                               </div>
                                            </div>
                                            <div class="row" v-if="showActionBtn">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save')}}</b-button>
                                                    &nbsp;
                                                    <router-link to="/incentive-grant-service/dae/financial-aid/farmer-selection" :class="'btn btn-danger text-light'">
                                                        {{ $t('globalTrans.cancel') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { getFarmer, farmerSelectStore } from '../../api/routes'

export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        id: this.$route.query.id,
        loading: false,
        crops: [],
        materials: [],
        search: {
            fiscal_year_id: 0,
            season_id: 0,
            circular_id: 0,
            district_id: 0,
            upazilla_id: 0
        },
        form: {
            fiscal_year_id: 0,
            season_id: 0,
            circular_id: 0,
            district_id: 0,
            upazilla_id: 0,
            details: [],
            total_farmer: ''
        },
        circularList: [],
        totalAmountColumnText: '',
        distributionPolicyCropInfo: {},
        distributionPolicyMatInfo: [],
        upazillaList: [],
        farmerDetails: [],
        detailFarmer: 0,
        showData: false,
        showActionBtn: false,
        circularItem: {},
        isReadOnly: false
      }
    },
    created () {
      if (this.$route.query.id) {
        this.getFormData(this.$route.query.id)
      }
    },
    watch: {
      'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.circularList = this.getCircularList(newVal)
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazillaList = this.getUpazilaList(newVal)
        }
      },
      'search.circular_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getUpazillaFarmerInfo()
        }
      },
      'search.upazilla_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getUpazillaFarmerInfo()
        }
      }
    },
    mounted () {
        core.index()
        if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
            this.isReadOnly = true
            this.search.district_id = this.authUser.office_detail.district_id
            this.search.upazilla_id = this.authUser.office_detail.upazilla_id
            this.getUpazillaFarmerInfo()
        }
    },
    computed: {
        authUser: function () {
            return this.$store.state.Auth.authUser
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        seasonSetupList: function () {
            return this.$store.state.incentiveGrant.commonObj.seasonSetupList
        },
        fiscalList: function () {
            return this.$store.state.commonObj.fiscalYearList
        },
        districtList: function () {
            return this.$store.state.commonObj.districtList
        }
    },
    methods: {
        getSeasonSetupList (fiscalYearId = null) {
            const seasonData = this.$store.state.incentiveGrant.commonObj.seasonSetupList.filter(item => item.status === 1 && item.fiscal_year_id === fiscalYearId)
            return seasonData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getCircularList (fiscalYearId) {
            const dataLlist = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === 4 && item.fiscal_year_id === fiscalYearId)
            return dataLlist.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        async saveData () {
            this.loading = true
            let result = null
            const formData = Object.assign(this.form, this.search)
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, farmerSelectStore, formData)
            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$router.push('/incentive-grant-service/dae/financial-aid/farmer-selection')
            } else {
                if (result.errors) {
                    this.$refs.form.setErrors(result.errors)
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: result.message,
                        color: '#D6E09B'
                    })
                }
            }
            this.loading = false
        },
        async getUpazillaFarmerInfo () {
            this.loading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, `${getFarmer}`, this.search)
            .then(response => {
                if (response.success) {
                    if (!this.$route.query.id) {
                        const calculatedAmount = parseFloat(response.allocation.total_amount / response.allocation.total_farmer)
                        const tmpDetails = response.data.map(item => {
                            return Object.assign(item, { far_general_info_id: item.id, amount: calculatedAmount })
                        })

                        this.form = Object.assign(this.form, {
                            total_farmer: response.allocation.total_farmer,
                            total_amount: response.allocation.total_amount,
                            detail_total_farmer: 0,
                            details: tmpDetails,
                            is_check: true
                        })
                    } else {
                        var detailTotalFarmer = 0
                        const tmpDetails = response.data.map(item => {
                            const farmer = this.farmerDetails.find(farmer => farmer.far_general_info_id === item.id)
                            detailTotalFarmer += typeof farmer !== 'undefined' ? 1 : 0
                            const calculatedAmount = (response.allocation.total_amount / response.allocation.total_farmer)
                            return Object.assign(item, {
                                far_general_info_id: item.id,
                                amount: calculatedAmount,
                                is_check: typeof farmer !== 'undefined' ? 1 : 0
                            })
                        })

                        this.form = Object.assign(this.form, {
                            total_farmer: response.allocation.total_farmer,
                            total_amount: response.allocation.total_amount,
                            detail_total_farmer: detailTotalFarmer,
                            details: tmpDetails
                        })
                    }
                    this.showData = true
                } else {
                    this.showData = false
                }
                this.loading = false
            })
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
            return upazilaList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        checkTotalFarmer (index, event) {
            if (parseInt(event) === 0) {
                this.form.detail_total_farmer -= 1
            } else {
                this.form.detail_total_farmer += 1
            }
            if (this.form.total_farmer < this.form.detail_total_farmer) {
                this.form.detail_total_farmer -= 1
                this.form.details[index].is_check = false
                this.showActionBtn = false
                this.showAlert()
            }

            if (parseInt(this.form.total_farmer) > 0 && this.form.detail_total_farmer > 0) {
                this.showActionBtn = true
            } else {
                this.showActionBtn = false
            }
        },
        showAlert () {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'Total Farmer Already Exceed',
                color: '#fff'
            })
        }
    }
}
</script>
